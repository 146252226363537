import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import VAuth from './views/Auth.vue'
import {getCurrentUser} from "@/rtdb";
import ScanView from "@/views/ScanView.vue";
import EventsView from "@/views/EventsView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/scan/:eventId',
        name: 'scan',
        component: ScanView
    }, {
        path: '/',
        name: 'home',
        component: EventsView
    },
    {
        path: '/auth',
        name: 'auth',
        component: VAuth
    },

]

const router = createRouter({
    history: createWebHashHistory(), // спользует # в пути , и сбербанк не может перенаправить на такой url клиента
    //history: createWebHistory(), // не спользует # в пути
    routes
})


router.beforeEach(async (to, from, next) => {
    // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const user = await getCurrentUser()
    if (!user && to.name != 'auth') {
        next('/auth')
    }
    if (user && to.name == 'auth') {
      next('/')
    } else {
        next()
    }
});

export default router
