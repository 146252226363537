import {get, runTransaction, set} from 'firebase/database'
import {getCurrentUser, getRef} from "@/rtdb";
import {DBScan, DBTicket, ScanMode, ScanResult} from "@/@types";

export async function getTicket(eventId: string, ticketId: string): Promise<DBTicket | undefined> {
    if (!eventId || !ticketId) return undefined

    return (await get(getRef(`tickets/${eventId}/${ticketId}`))).val() as DBTicket

    // const scans = (await get(query(getRef(`scans/${eventId}/${ticketId}`), orderByKey(), limitToLast(99)))).val() as DBScans
    //
    // if (ticket)
    //     return {
    //         ...ticket,
    //         inside: !scans || (Object.values<DBScan>(scans)[0].direction === ScanMode.ENTER),
    //         scans
    //     }
    // else
    //     return undefined

}


export async function scanEnter3(eventId: string, ticketId: string, mode: ScanMode.ENTER | ScanMode.EXIT) {

    const ticket = getTicket(eventId, eventId)
    if (!ticket) return;

    // todo тут проверка на проход последний, совпадает ли направление

    const data: DBScan = {
        direction: mode,
        timestamp: Date.now(),
        user: (await getCurrentUser())?.email ?? '',  // todo исправить, всегда юзер нужен
        entrance: ''
    }

    await set(getRef(`scans/${eventId}/${ticketId}`), data)

}

// Проверить что билет может войти и пометить что вошел
export async function scanEnter2(eventId: string, ticketId: string, mode: ScanMode.ENTER | ScanMode.EXIT): Promise<ScanResult | undefined> {

    const ticket = (await get(getRef(`tickets/${eventId}/${ticketId}`))).val() as DBTicket
    if (!ticket) {
        console.error('Нет билета в списке', ticketId)
        return undefined;
    }

    let lastScan = 0

    // проверяем что не было входов и записываем пометку входа
    const result = await runTransaction(getRef(`scans/${eventId}/${ticketId}`), (data?: DBScan) => {

        // Не было сканирований
        if (!data) {
            return {
                direction: ScanMode.ENTER,
                timestamp: Date.now(),
                //user: (await getCurrentUser())?.email
            }

        // Уже сканировался, проверяем направление
        } else if (data.direction === ScanMode.EXIT) {
            data.direction = ScanMode.ENTER
            data.timestamp = Date.now()
            return data

        // Не было сканирований, или последнее сканирование в то же направление
        } else {
            lastScan = data.timestamp
            return;  // что бы прервать транзакцию надо - not returning a value
        }

    });

    console.log('transaction result', result)

    return {
        ...ticket,
        ok: !!result.committed,
        lastScan: lastScan
    }

}


export async function scanInfo(eventId: string, ticketId: string) {

    return getTicket(eventId, ticketId)

}

