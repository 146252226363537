import {getDatabase, ref} from "@firebase/database"
import {initializeApp} from 'firebase/app';
import {getAuth, User} from "firebase/auth"
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
//import {getAuth, User} from "firebase/auth"


// Это не секреты
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAcfEJXGvm9sUK8sAwvD3IDzP3mJ4jy8dk",
    authDomain: "ticketcafe.firebaseapp.com",
    databaseURL: "https://ticketcafe-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ticketcafe",
    storageBucket: "ticketcafe.appspot.com",
    messagingSenderId: "635114167262",
    appId: "1:635114167262:web:371ef0af354bfec7771b4f",
    measurementId: "G-2K474VD08Y"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
//export const analytics = getAnalytics(app)

// Initialize Performance Monitoring and get a reference to the service
//const perf = getPerformance(app)

const rtdb = getDatabase(app, 'https://ticketcafe-default-rtdb.asia-southeast1.firebasedatabase.app/')

const auth = getAuth(app)

export const firebaseUI = new firebaseui.auth.AuthUI(auth)

export const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            console.log('signInSuccessWithAuthResult')
            return true;
        },
        uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            //document.getElementById('loader').style.display = 'none';
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    //tosUrl: '<your-tos-url>',
    // Privacy policy url.
    //privacyPolicyUrl: '<your-privacy-policy-url>'
}

export function getRef(path?: string){
    return ref(rtdb, '/scan/'+path)
}

export let currentUser: User | null

auth.onAuthStateChanged(function(user) {
    console.log('onAuthStateChanged', user)
    currentUser = user
})

export function getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
            const unsubscribe = getAuth(app).onAuthStateChanged(user => {
                unsubscribe();
                resolve(user);
            }, reject);
        }
    )}

