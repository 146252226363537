

import {Component, Vue} from "vue-facing-decorator";

@Component({
  components: {

  }
})
export default class App extends Vue {

  created() {

    console.log('TicketScan APP created NODE_ENV=' + process.env.NODE_ENV)

    if (!process.env.VUE_APP_API_URL)
      console.error('env.VUE_APP_API_URL not defined!')

  }

}

