import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, mdi} from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#ea5f5a', //'#d39c23', // #FF6E40
                    secondary: '#90A4AE'
                }
            },
        },
    },
    icons: {
        defaultSet: 'mdi',  // даже при дефолтном сете нужно или установить npm install @mdi/font или прописать CDN
        aliases,
        sets: {
            mdi,
        },
    },
})


createApp(App)
    .use(vuetify)
    .use(router)
    .mount('#app')
