


import 'firebaseui/dist/firebaseui.css'

import {Component, Vue} from "vue-facing-decorator";
import {getCurrentUser, getRef} from "@/rtdb";
import {get, onValue, push, set} from 'firebase/database';
import {DBEvents} from "@/@types";


@Component({
  components: {}
})
export default class EventsView extends Vue {

  events: DBEvents | null = null

  newEventName = ''
  dialogNewEvent = false
  dialogDeleteEvent = false

  deleteEventKey = ''

  isAdmin = false

  async addNewEvent() {
    await push(getRef(`events/`),{
      name: this.newEventName
    })
    this.dialogNewEvent = false

  }

  async deleteEvent() {
    if (!this.deleteEventKey) return;

    await set(getRef(`events/`+this.deleteEventKey), null)

    this.dialogDeleteEvent = false
  }

  async mounted() {

    this.isAdmin = !!(await get(getRef(`admins/${(await getCurrentUser())?.uid ?? 0}/`))).val()

    onValue(getRef('events'), snap => {

      this.events = snap.val() ?? {}


    })


  }

}

