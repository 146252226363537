


import 'firebaseui/dist/firebaseui.css'

import {Component, Vue} from "vue-facing-decorator";
import {firebaseUI, uiConfig} from "@/rtdb";


@Component({
  components: {}
})
export default class TCEventMap extends Vue {

  mounted() {
    firebaseUI.start('#firebaseui-auth-container', uiConfig)
  }

}

