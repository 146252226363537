export type DBEvent = {

    name?: string
    date?: number
    place?: string
    comment?: string
}

export type DBEvents = { [key: string]: DBEvent }

export type DBTicket = {

    group?: ''
    desc?: string
    blocked?: boolean
    form?: ''
    price?: number

}
export type DBTickets = { [key: string]: DBTicket }

export type DBScan = {

    entrance: string
    direction: ScanMode.EXIT | ScanMode.ENTER
    timestamp: number
    user: string

}

export type DBScans = { [key: string]: DBScan }

export enum ScanMode {
    INFO,
    ENTER,
    EXIT
}

export type EventUsers = {
    [key: string]: {
        access: boolean,
        email: string
    }
}

export type ScanResult = DBTicket & {
    ok: boolean // временно это признак успеха
    lastScan: number
    // todo lastDevice: string
    //scans?: DBScans
}
